import { ANALYTICS_CSS_ID } from '../../../../constants';
import { menuSelectors, useMenuStore } from 'stores';
import numberLocaleFormat, { CURRENCY_TO_LOCALE_PRICE } from 'utils/numberLocaleFormat';
import { Icon } from '@sicpama/core-components';

interface MenuItemCarouselProps {
  id: number;
  onClick: () => void;
}

export default function MenuItemCarousel({ id, onClick }: MenuItemCarouselProps): JSX.Element {
  const menu = useMenuStore(menuSelectors.getBaseMenuById(id));

  return (
    <div
      className="flex text-sm gap-2 bg-[#F5F5F5] items-start rounded-lg relative"
      onClick={onClick}
      id={`${ANALYTICS_CSS_ID(menu?.id).EVENT_MENU}`}
    >
      <img
        src={menu?.photo ?? 'images/no-image.png'}
        className="w-[80px] h-[80px] object-cover rounded-lg"
        style={{
          filter: 'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.4))',
        }}
      />
      <div className="max-w-[130px] flex flex-col justify-between min-h-[60px]">
        <div className="pt-1 font-bold text-[#222222] truncate">{menu?.name}</div>
        <div className="flex gap-2">
          <span
            className={`${
              menu?.discountPrice ? 'line-through text-[#222222]' : 'font-bold'
            } text-theme`}
          >
            {numberLocaleFormat(+menu?.price, CURRENCY_TO_LOCALE_PRICE[menu?.currency])}
          </span>
          {menu?.discountPrice && (
            <span className="text-theme font-bold">
              {numberLocaleFormat(+menu?.discountPrice, CURRENCY_TO_LOCALE_PRICE[menu?.currency])}
            </span>
          )}
        </div>
      </div>
      <div className="absolute bottom-0 right-1 rounded-full flex items-center justify-center w-[20px] h-[20px] bg-theme">
        <Icon name="Plus" size={16} color="white" className="" />
      </div>
    </div>
  );
}
