import { Carousel } from '@sicpama/core-components';
import MenuDetailSheet from './MenuDetailSheet';
import type { ReactElement, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import useModal from '../../hooks/useModal';
import { useMenuStore } from '../../stores/menu';
import numberLocaleFormat, { CURRENCY_TO_LOCALE_PRICE } from '../../utils/numberLocaleFormat';
import { ANALYTICS_CSS_ID } from 'constants/analytics.constant';
import { IBaseMenuResDto, Nullable } from '../../submodules/sicpama-shared';

const DiscountCarousel = (): ReactElement => {
  const { menusByCategories } = useMenuStore();
  const { opened: menuOpened, closeModal: closeMenuDetail, openModal: openMenuDetail } = useModal();
  const [selectedMenuId, setSelectedMenuId] = useState<Nullable<number>>(null);

  const discountMenus = useMemo(() => {
    if (!menusByCategories.length) return [];
    const menus = menusByCategories.flatMap((category) => category.menus);
    const topPriorityMenus = menus.filter((menu) => menu.discountPrice || menu.priority);
    if (!topPriorityMenus.length) return menus.slice(0, 5);
    const result = topPriorityMenus;
    return result;
  }, [menusByCategories]);

  const getDiscountMenuView = (menu: IBaseMenuResDto): ReactNode => {
    return (
      <div
        className="flex flex-col w-[160px] text-sm gap-1"
        onClick={() => {
          setSelectedMenuId(menu.id);
          openMenuDetail();
        }}
        id={`${ANALYTICS_CSS_ID(menu?.id).EVENT_MENU}`}
      >
        <img
          src={menu.photo ?? 'images/no-image.png'}
          className="w-[160px] h-[160px] object-cover rounded-lg"
          style={{
            filter: 'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.4))',
          }}
        />
        <div className="mt-1 font-bold text-[#222222] truncate ...">{menu.name}</div>
        <div className="flex gap-2">
          <span className={`${menu.discountPrice ? 'line-through' : ''} text-[#868686]`}>
            {numberLocaleFormat(+menu.price, CURRENCY_TO_LOCALE_PRICE[menu.currency])}
          </span>
          {menu.discountPrice && (
            <span className="text-theme font-bold">
              {numberLocaleFormat(+menu.discountPrice, CURRENCY_TO_LOCALE_PRICE[menu.currency])}
            </span>
          )}
        </div>
        {/* <div className="flex items-start gap-2 text-theme">
          <Badge
            title="1/N"
            className="w-fit shrink-0"
            borderColor={themeColor}
            backgroundColor="white"
            titleColor={themeColor}
          />
        </div> */}
      </div>
    );
  };

  return (
    <div className="w-full py-4 px-4">
      <Carousel
        items={discountMenus.map((menu) => getDiscountMenuView(menu))}
        slideSize="160px"
        slideGap="1rem"
        loop={true}
        align="start"
        withControls={false}
      />
      <MenuDetailSheet
        isOpen={menuOpened}
        onClose={closeMenuDetail}
        menuId={selectedMenuId}
        onAddLessThanMinQuantityCallback={openMenuDetail}
      />
    </div>
  );
};

export default DiscountCarousel;
