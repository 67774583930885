import { TFunction } from 'i18next';
import { MAPPING_ISSUER_NAMES } from '../../constants';
import { IReceiptInformation } from '../../submodules/sicpama-shared';

export const transformCreditCardIssuer = (cardIssuer: string, t: TFunction): string => {
  if (MAPPING_ISSUER_NAMES.includes(cardIssuer)) {
    return t(`creditCardIssuers.${cardIssuer}`);
  }

  const lastCharacter = cardIssuer.slice(-1);

  if (lastCharacter === '사') {
    return cardIssuer.slice(0, -1);
  }

  return cardIssuer;
};

export const isReceiptObjectEmpty = (object: IReceiptInformation | null): boolean => {
  if (!object) return true;

  return Object.keys(object).length === 0 && object.constructor === Object;
};
