import { IMenuDetailsResDto, IMenuOrderItemResDto, Nullable } from '../submodules/sicpama-shared';

export const DEFAULT_KRW_PRICE_OPTION: Intl.NumberFormatOptions = {
  style: 'currency',
  currency: 'KRW',
  useGrouping: true,
};

export const CURRENCY_TO_LOCALE_PRICE: Record<string, Intl.NumberFormatOptions> = {
  KRW: DEFAULT_KRW_PRICE_OPTION,
  SGD: {
    style: 'currency',
    currency: 'SGD',
    useGrouping: true,
  },
};

export const roundNumberTo2DecimalPlaces = (num: number): number =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const numberLocaleFormat = (
  number: number,
  options: Intl.NumberFormatOptions,
  locale = 'en-SG',
): string => {
  return number.toLocaleString(locale, { ...options });
};

export const localePrice = (currency: string, price?: Nullable<number>): string => {
  if (!price) {
    return '';
  }
  return numberLocaleFormat(+price, CURRENCY_TO_LOCALE_PRICE[currency]);
};

export const isDiscounted = (menuConfig: IMenuDetailsResDto | IMenuOrderItemResDto): boolean => {
  return (menuConfig.discountPrice ?? null) !== null;
};

export const formattedDiscountPrice = (
  menuConfig: IMenuDetailsResDto | IMenuOrderItemResDto,
): string => {
  return localePrice(menuConfig.currency, menuConfig.discountPrice);
};

export default numberLocaleFormat;
