import { Button, Icon } from '@sicpama/core-components';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { PublicContainer } from 'components';
import { CenterLoader } from 'components/loader';
import { LOCAL_STORAGE_KEY } from '../../constants';
import { ANALYTICS_CSS_ID } from 'constants/analytics.constant';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import debounce from 'utils/debounce';
import Divider from '../../components/divider';
import Footer from '../../components/footer';
import { useCouponAdvertStore, useMenuStore, useThemeStore } from '../../stores';
import CategoryMenus from './CategoryMenus';
import DiscountCarousel from './DiscountCarousel';
import {
  ICategoryMenuResDto,
  Nullable,
  STORE_ATTRIBUTE_NAME,
} from '../../submodules/sicpama-shared';
import ReactPixel from 'react-facebook-pixel';
import { storeService, customerService } from '../../services';
import { useTranslation } from 'react-i18next';
import SmallLoginModal from 'pages/login/SmallModal';
import { CouponClaimedSuccess } from './CouponClaimedSuccess';

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const translationPrefix = 'pages.readonly-menu';
// THis one must be set in the .env file in advance so will already have a value
const metaPixelId = process.env.REACT_APP_META_PIXEL_ID!;

const ReadOnlyMenuList: React.FC = () => {
  const { t } = useTranslation();

  const { storeId, couponId } = useParams<{ storeId: string; couponId: string }>();

  const {
    couponAdvertHtml,
    isLoading,
    error,
    fetchCouponAdvert,
    couponClaimedSuccessHtml,
    signUpForCouponAdvertHtml,
  } = useCouponAdvertStore();

  const session = useSessionContext();

  // Fetch the coupon advert when storeId and couponId are available
  useEffect(() => {
    if (storeId && couponId) {
      fetchCouponAdvert(storeId, couponId);
    }
  }, [storeId, couponId, fetchCouponAdvert]);

  // Meta Pixel Code
  useEffect(() => {
    ReactPixel.init(metaPixelId ?? '', undefined, options);
    ReactPixel.pageView();
  }, []);

  const containerRef = useRef(null);
  const {
    menusByCategories: categories,
    getMenusByStoreId,
    isLoading: isMenuLoading,
  } = useMenuStore();

  const { store } = useThemeStore();
  const { attributes } = store;
  const priceNoteAttribute = attributes?.find((x) => x.name === STORE_ATTRIBUTE_NAME.PRICE_NOTE);
  const priceNoteValue = priceNoteAttribute?.value;
  const [selectedCategory, setSelectedCategory] = useState<Nullable<ICategoryMenuResDto>>(null);
  const categoryBarRef = useRef<HTMLDivElement>(null);
  const categoryListRef = useRef<HTMLDivElement[]>([]);
  const intersectingListRef = useRef<Array<boolean | undefined>>([]);
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [couponClaimedSuccessVisible, setCouponClaimedSuccessVisible] = useState(false);

  useEffect(() => {
    if (categories.length && !selectedCategory) {
      setSelectedCategory(categories[0]);
    }
  }, [categories]);

  const setIntersectingState = useCallback(
    (categoryIndex: number) => (state: boolean) => {
      intersectingListRef.current[categoryIndex] = state;
      const intersectingList = intersectingListRef.current;
      const topIntersectCategoryIndex = intersectingList.reduce(
        (acc, cur, index) => (cur ? (acc > -1 ? Math.min(acc, index) : index) : acc),
        -1,
      );
      setSelectedCategory(categories[topIntersectCategoryIndex]);
      debounceScrollIntoView(topIntersectCategoryIndex);
    },
    [categories],
  );

  const debounceScrollIntoView = useCallback(
    debounce<[number]>((topIntersectCategoryIndex) => {
      const categoryCell = categoryListRef.current[topIntersectCategoryIndex];
      const categoryBar = categoryBarRef.current;
      if (categoryCell && categoryBar) {
        if (
          +categoryCell.offsetLeft + +categoryCell.offsetWidth >
            +categoryBar.scrollLeft + +categoryBar.offsetWidth ||
          +categoryCell.offsetLeft < +categoryBar.scrollLeft - 16
        ) {
          categoryBar.scrollTo(categoryCell.offsetLeft - 16, 0);
        }
      }
    }, 100),
    [],
  );

  const onCategoryTabClicked = useCallback((category: ICategoryMenuResDto) => {
    setSelectedCategory(category);
    const categoryContent = document.querySelector(`#category-tab-${category.categoryId}`);
    const container = containerRef.current;
    if (categoryContent && container) {
      const offsetTop = (categoryContent as HTMLDivElement).offsetTop;
      (container as HTMLDivElement).scrollTo(0, offsetTop - 56);
    }
  }, []);

  useEffect(() => {
    if (storeId) {
      getMenusByStoreId(+storeId);
    }
  }, [storeId]);

  useEffect(() => {
    const handleClaimCoupon = async (_storeId: string, _couponId: string): Promise<void> => {
      try {
        const customerInfo = await customerService.getCustomerInformation();
        const customerId = customerInfo.id;
        ReactPixel.trackCustom(`Prepare to claim coupon`, {
          storeId,
          couponId,
          customerId,
        });
        const response = await storeService.couponPickup(_storeId, _couponId, customerId);
        sessionStorage.setItem(LOCAL_STORAGE_KEY.ALREADY_CLAIMED_COUPON, 'true');
        // Note: Some side effects when navigate back from KakaoTalk app can cause handleClaimCoupon called twice
        // Now temporarily treat this as a workaround for You already have this coupon
        if (
          [
            'Your Coupon is ready for your visit to the store',
            'You already have this coupon',
          ].includes(response.message)
        ) {
          ReactPixel.trackCustom(`Claimed coupon successfully after login`, {
            storeId,
            couponId,
            customerId: customerInfo.id,
          });
          setCouponClaimedSuccessVisible(true);
        } else {
          alert(response.message);
        }
      } catch (err: unknown) {
        if (err instanceof Error) {
          alert(`Failed to pick up coupon: ${err.message}`);
        } else {
          alert(`Failed to pick up coupon: ${String(err)}`);
        }
      }
    };

    // After login, it is supposed to navigate to this page again
    // then we need to make sure to automatically call api pick up coupon only one time
    const isCalledAPIToClaimCoupon = sessionStorage.getItem(
      LOCAL_STORAGE_KEY.ALREADY_CLAIMED_COUPON,
    );

    console.info('isCalledAPIToClaimCoupon: ', isCalledAPIToClaimCoupon);
    console.info('session: ', session);

    const isCustomerLoggedIn = !session.loading && session.doesSessionExist;
    if (storeId && couponId && !isCalledAPIToClaimCoupon && isCustomerLoggedIn) {
      handleClaimCoupon(storeId, couponId);
    }
  }, [storeId, couponId, session]);

  const loginOptions = useMemo(() => {
    const loginOptionsAttribute = store?.attributes?.find(
      (x) => x.name === STORE_ATTRIBUTE_NAME.LOGIN_OPTIONS,
    );
    return JSON.parse(loginOptionsAttribute?.value ?? '[]');
  }, [store]);

  if (isMenuLoading || session.loading) {
    return <CenterLoader />;
  }

  return couponId ? (
    <PublicContainer showSearch={false} containerRef={containerRef} showProgressBar={false}>
      <div>
        {!isLoading && !error && <div dangerouslySetInnerHTML={{ __html: couponAdvertHtml }} />}
        {!session.doesSessionExist && (
          <div className="px-4 flex flex-col items-center mb-4">
            <Button
              onClick={() => {
                localStorage.setItem(
                  LOCAL_STORAGE_KEY.BEFORE_SIGN_IN_PATH,
                  window.location.pathname,
                );
                ReactPixel.trackCustom(`Click on get coupon`, { storeId, couponId });
                setLoginModalVisible(true);
              }}
              className="rounded-lg bg-theme text-white font-semibold"
              title={t(`${translationPrefix}.logInToClaim`)}
              size="md"
            />
          </div>
        )}
        <SmallLoginModal
          opened={loginModalVisible}
          closeModal={() => {
            setLoginModalVisible(false);
          }}
          paymentOption={''}
          headerTitle={t(`${translationPrefix}.logInToClaim`) || ''}
          htmlContent={signUpForCouponAdvertHtml}
          loginOptions={loginOptions}
        />
        <CouponClaimedSuccess
          visible={couponClaimedSuccessVisible}
          closeModal={() => {
            setCouponClaimedSuccessVisible(false);
          }}
          htmlContent={couponClaimedSuccessHtml}
        />{' '}
      </div>
      <Footer />
    </PublicContainer>
  ) : (
    <PublicContainer showSearch={true} containerRef={containerRef} showProgressBar={false}>
      <Divider className="!min-h-[8px] !h-[8px] my-2" />
      <DiscountCarousel />
      <Divider className="!min-h-[8px] !h-[8px] my-2" />
      <div className="px-4 py-1 overflow-hidden sticky top-[56px] z-10 shrink-0 mt-6 bg-white border-b">
        <div
          className="flex overflow-y-hidden overflow-x-auto w-full scrollbar-hide gap-2"
          ref={categoryBarRef}
        >
          {categories.map((category, index) => (
            <div
              key={category.categoryId}
              ref={(el) => {
                if (el !== null) {
                  categoryListRef.current[index] = el;
                }
              }}
              onClick={() => {
                onCategoryTabClicked(category);
              }}
              className={clsx(
                'flex items-center justify-center min-w-[92px] h-full text-sm overflow-hidden shrink-0 px-3 py-2 rounded border border-theme',
                selectedCategory === category
                  ? 'bg-theme font-bold text-[#ffffff]'
                  : 'bg-white text-theme',
              )}
              id={ANALYTICS_CSS_ID(category.categoryId).CATEGORY}
            >
              {category.categoryName}
            </div>
          ))}
        </div>
      </div>
      {priceNoteValue && (
        <div
          className="mx-4 mt-2 gap-2 py-2 px-4 flex items-center bg-[#F7F7F7]"
          id={ANALYTICS_CSS_ID().MENU_PAGE.NOTIFICATION_BANNER}
        >
          <Icon name="InfoCircle" type="filled" size={14} color="#999" />
          <p className="text-xs text-[#4d4d4d]">{priceNoteValue}</p>
        </div>
      )}
      <div className="flex flex-col bg-white p-4 min-h-0 mb-4">
        {categories.map((category, index) => (
          <CategoryMenus
            key={category.categoryId}
            category={category}
            setIntersectingState={setIntersectingState(index)}
          />
        ))}
      </div>
      <Footer />
    </PublicContainer>
  );
};

export default ReadOnlyMenuList;
