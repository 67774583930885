import { Button, Divider, Grid, Icon } from '@sicpama/core-components';
import { ReactElement, useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { notifications } from '@sicpama/core-components/lib/notifications';
import html2canvas from 'html2canvas';
import saveAs from 'file-saver';
import { useOrderStore, useThemeStore } from '../../stores';
import { isReceiptObjectEmpty, transformCreditCardIssuer } from './utils';
import numberLocaleFormat, { CURRENCY_TO_LOCALE_PRICE } from '../../utils/numberLocaleFormat';
import { COUNTRY_CODE } from '../../constants';

import './receipt.scss';
import { getOrderNumber, IReceiptInformation } from '../../submodules/sicpama-shared';

const translationPrefix = 'pages.receipt';

const DinerReceipt = (): ReactElement => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const divRef = useRef<HTMLDivElement>(null);

  const {
    theme: { color: themeColor },
  } = useThemeStore();

  const { receiptOrder, setReceiptInformation } = useOrderStore();

  const [subTotal, setSubTotal] = useState<number>(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderIdFromParam = urlParams.get('orderId');

    if (orderIdFromParam && isReceiptObjectEmpty(receiptOrder)) {
      setReceiptInformation(orderIdFromParam);
    }
  }, [receiptOrder]);

  useEffect(() => {
    if (Object.keys(receiptOrder).length !== 0) {
      setSubTotal(receiptOrder.order.amountExcludingTax);
    }

    if (
      Object.keys(receiptOrder).length !== 0 &&
      receiptOrder.order?.amountExcludingTaxAndServiceCharge
    ) {
      setSubTotal(receiptOrder.order.amountExcludingTaxAndServiceCharge);
    }
  }, [receiptOrder]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSaveReceipt = () => {
    notifications.show({
      withCloseButton: true,
      autoClose: 2000,
      message: t(`${translationPrefix}.successSaveReceipt`),
      color: 'dark',
    });
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleCaptureClick = async () => {
    const userAgent = navigator.userAgent;
    if (!divRef.current) return;

    divRef.current.style.overflow = 'visible';
    divRef.current.style.width = 'auto';
    divRef.current.style.height = 'auto';

    const generateFileName = (prefix: string): string => {
      const timestamp = new Date()
        .toISOString()
        .replace(/[-:.T]/g, '')
        .slice(0, 14);
      return `${prefix}_${timestamp}.png`;
    };

    if (/android/i.test(userAgent)) {
      try {
        const div = divRef.current;
        const canvas = await html2canvas(div, { scale: 2 });
        canvas.toBlob((blob) => {
          if (blob !== null) {
            const fileName = generateFileName(t(`${translationPrefix}.receipt`));
            saveAs(blob, fileName);
          }
        });
      } catch (error) {
        console.error('Error converting div to image:', error);
      }
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      try {
        const div = divRef.current;
        const canvas = await html2canvas(div, { scale: 2 });
        canvas.toBlob(async (blob) => {
          if (blob !== null) {
            const fileName = generateFileName(t(`${translationPrefix}.receipt`));
            saveAs(blob, fileName);

            if (navigator.share) {
              const file = new File([blob], fileName, { type: blob.type });
              try {
                await navigator.share({
                  files: [file],
                  title: 'Receipt',
                  text: 'Here is your receipt.',
                });
              } catch (shareError) {
                console.error('Error sharing the receipt:', shareError);
              }
            } else {
              console.error('Web Share API not supported.');
            }
          }
        });
      } catch (error) {
        console.error('Error converting div to image:', error);
      }
    } else {
      console.error('The device is neither Android nor iPhone.');
    }
  };

  const renderServiceCharge = (orderInfo: IReceiptInformation): ReactElement => {
    if (orderInfo.order?.serviceCharge && +orderInfo.order.serviceCharge > 0) {
      return (
        <div className="ml-4 mr-4 text-[#999999] text-[13px]">
          <div className="mb-2">
            <Grid spans={[3, 9]}>
              <p>{t(`${translationPrefix}.serviceCharge`)}</p>
              <p>{getLocaleAmount(+orderInfo.order.serviceCharge)}</p>
            </Grid>
          </div>
        </div>
      );
    }

    return <></>;
  };

  const getLocaleAmount = useCallback(
    (amount: number): string => {
      if (Object.keys(receiptOrder).length > 0) {
        const currency = receiptOrder.store.countryCode === COUNTRY_CODE.KR ? 'KRW' : 'SGD';
        return numberLocaleFormat(amount, CURRENCY_TO_LOCALE_PRICE[currency]);
      }

      return '';
    },
    [receiptOrder],
  );

  return (
    <div className="flex flex-col h-auto ">
      {Object.keys(receiptOrder).length !== 0 && (
        <>
          <div ref={divRef} className="h-full pb-[12rem] overflow-y-scroll safe-top">
            <div className="pl-2 pt-4">
              <Icon
                name="ArrowLeft"
                color={themeColor}
                size={36}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <div className="pt-6 pb-6 text-xl text-center">
              <h1>{t(`${translationPrefix}.receipt`)}</h1>
            </div>

            <h3 className="ml-4 mr-4 text-sm font-medium">{t(`${translationPrefix}.salesSlip`)}</h3>
            <Divider className="mt-2 mb-2" />
            <div className="ml-4 mr-4 text-[#999999] text-[13px]">
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.ApprovalNumber`)}</p>
                  <p>{receiptOrder.order.approvalCode}</p>
                </Grid>
              </div>
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.cardType`)}</p>
                  <p>{transformCreditCardIssuer(receiptOrder.order.creditCardIssuer, t)}</p>
                </Grid>
              </div>
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.cardNumber`)}</p>
                  <p>{receiptOrder.order.creditCardNumber}</p>
                </Grid>
              </div>
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.installment`)}</p>
                  <p>{t(`${translationPrefix}.payInFull`)}</p>
                </Grid>
              </div>
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.paymentDate`)}</p>
                  <p>{receiptOrder.order.paidAt}</p>
                </Grid>
              </div>
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.cancel`)}</p>
                  <p></p>
                </Grid>
              </div>
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.orderMenu`)}</p>
                  <p>{receiptOrder.order.itemName}</p>
                </Grid>
              </div>
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.orderNumber`)}</p>
                  <p>{getOrderNumber(receiptOrder.order.id)}</p>
                </Grid>
              </div>
            </div>

            <h3 className="mt-10 ml-4 mr-4 text-sm font-medium">
              {t(`${translationPrefix}.paidAmount`)}
            </h3>
            <Divider className="mt-2 mb-2" />
            <div className="ml-4 mr-4 text-[#999999] text-[13px]">
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.amountExcludedTax`)}</p>
                  <p>{getLocaleAmount(+subTotal)}</p>
                </Grid>
              </div>
            </div>
            {renderServiceCharge(receiptOrder)}
            <div className="ml-4 mr-4 text-[#999999] text-[13px]">
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.tax`)}</p>
                  <p>{getLocaleAmount(+receiptOrder.order.tax)}</p>
                </Grid>
              </div>
            </div>
            <div className="ml-4 mr-4 text-[#999999] text-[13px]">
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.amountIncludedTax`)}</p>
                  <p>{getLocaleAmount(+receiptOrder.order.totalAmount)}</p>
                </Grid>
              </div>
            </div>

            <h3 className="mt-10 ml-4 mr-4 text-sm font-medium">
              {t(`${translationPrefix}.sellerInfo`)}
            </h3>
            <Divider className="mt-2 mb-2" />
            <div className="ml-4 mr-4 text-[#999999] text-[13px]">
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.storeName`)}</p>
                  <p>{receiptOrder.store.storeName}</p>
                </Grid>
              </div>
              {receiptOrder.store.ownerName && (
                <div className="mb-2">
                  <Grid spans={[3, 9]}>
                    <p>{t(`${translationPrefix}.repName`)}</p>
                    <p>{receiptOrder.store.ownerName}</p>
                  </Grid>
                </div>
              )}
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.businessNumber`)}</p>
                  <p>{receiptOrder.store.businessNumber}</p>
                </Grid>
              </div>
              <div>
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.address`)}</p>
                  <p>{receiptOrder.store.address}</p>
                </Grid>
              </div>
            </div>

            {receiptOrder.store.countryCode === 'KR' ? (
              <h3 className="mt-10 ml-4 mr-4 text-sm font-medium">
                {t(`${translationPrefix}.merchantInfo`)}
              </h3>
            ) : (
              <h3 className="flex items-center mt-10 ml-4 mr-4 space-x-2 text-sm font-medium">
                Powered by &nbsp;
                <img
                  src="/icons/SicPama_Signature_A_Web.svg"
                  alt="SicPama Logo"
                  width={100}
                  height={60}
                />
              </h3>
            )}

            <Divider className="mt-2 mb-2" />
            <div className="ml-4 mr-4 text-[#999999] text-[13px]">
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.merchantName`)}</p>
                  <p>
                    {t(`${translationPrefix}.sicpamaInfo.${receiptOrder.store.countryCode}.name`)}
                  </p>
                </Grid>
              </div>
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.repName`)}</p>
                  <p>
                    {t(
                      `${translationPrefix}.sicpamaInfo.${receiptOrder.store.countryCode}.representative`,
                    )}
                  </p>
                </Grid>
              </div>
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.businessNumber`)}</p>
                  <p>
                    {t(
                      `${translationPrefix}.sicpamaInfo.${receiptOrder.store.countryCode}.businessNumber`,
                    )}
                  </p>
                </Grid>
              </div>
              <div className="mb-2">
                <Grid spans={[3, 9]}>
                  <p>{t(`${translationPrefix}.address`)}</p>
                  <p>
                    {t(
                      `${translationPrefix}.sicpamaInfo.${receiptOrder.store.countryCode}.address`,
                    )}
                  </p>
                </Grid>
              </div>
            </div>
          </div>
          <div className="fixed bottom-0 w-full px-4 pt-4 pb-4 mt-auto bg-white">
            <Button
              className="w-full bg-theme"
              title={t(`${translationPrefix}.saveReceipt`)}
              onClick={() => {
                handleCaptureClick();
                handleSaveReceipt();
              }}
              titleColor="white"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DinerReceipt;
